import React from 'react'
import { Box, Typography } from '@mui/material'
import { Link } from 'react-router-dom'

const Hero = ({ data, onClickStyle, onClick }) => {
  return (
    <Box
      component={Link}
      to={data.order}
      onClick={onClick}
      sx={{
        ...onClickStyle,
        backgroundImage: `url(${data.imgUrl})`,
        backgroundAttachment: 'fixed',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPositionX: 'center',
        backgroundPositionY: 'center',

        height: { xs: '50vh', md: '100vh' },
        width: { xs: '100vw', md: '50vw' },
        textDecoration: 'none',
        '&:hover': {
          backgroundPositionX: 'right',
        },
        transition: '1000ms',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
      }}
    >
      {' '}
      <Box
        sx={{
          height: { xs: '50vh', md: '100vh' },
          width: { xs: '100vw', md: '50vw' },
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
          '&:hover': {
            backgroundColor: 'rgba(0,0,0,0.2)',
          },
          backgroundColor: 'rgba(0,0,0,0.7)',
        }}
      >
        {' '}
        <Typography
          variant="h3"
          color="#FFFFFF"
          sx={{
            fontSize: { xs: '2rem', sm: '3rem', md: '4rem', lg: '5rem' },
            border: '1px solid #ffffff',
            p: 4,
          }}
        >
          {data.desc}
        </Typography>
      </Box>
    </Box>
  )
}

export default Hero
