import { useState } from 'react'

const useDialog = (initialOpen = false) => {
  const [isOpen, setIsOpen] = useState(initialOpen)

  // Function to open the dialog
  const openDialog = () => {
    setIsOpen(true)
    console.log('Dialog opened:', new Date())
  }

  // Function to close the dialog
  const closeDialog = () => {
    setIsOpen(false)
    console.log('Dialog closed:', new Date())
  }

  // Toggle function to switch between open and close
  const toggleDialog = () => {
    setIsOpen((prevOpen) => !prevOpen)
  }

  return {
    isOpen,
    openDialog,
    closeDialog,
    toggleDialog,
  }
}

export default useDialog
