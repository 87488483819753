import React, { useState } from 'react'
import { Box } from '@mui/material'
import useRead from '../hooks/CRUD/useRead'

import Hero from './Hero'
import Loader from '../Loader'

const ViewPortFolio = () => {
  const { data, loading } = useRead('portfolio')
  const [isClicked, setClicked] = useState(false)
  const onClickStyle = {
    transition: 'transform 0.5s ease-in-out, opacity 0.3s ease-in-out',
    transform: isClicked ? 'scale(1.1)' : 'scale(1)',
  }
  const handleOnClick = () => {
    setClicked(true)
  }

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
          }}
        >
          {data.map((item) => {
            return (
              <Hero
                data={item}
                onClickStyle={onClickStyle}
                onClick={handleOnClick}
              />
            )
          })}
        </Box>
      )}
    </>
  )
}

export default ViewPortFolio
