import React from 'react'

import { Typography, Box, Fade, IconButton } from '@mui/material'
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined'

import { Link } from 'react-router-dom'
import Loader from '../Loader'
import useGetFileById from '../hooks/useGetFileById'

const ViewHome = () => {
  const Keyframes = () => (
    <style>
      {`
        @keyframes bounce {
          0%, 20%, 50%, 80%, 100% {
            transform: translateX(0);
          }
          40% {
            transform: translateX(30px);
          }
          60% {
            transform: translateX(15px);
          }
        }

        @keyframes expandAnimation {
          0% {
            transform: scale(0);
          }
          100% {
            transform: scale(1);
          }
        }
      `}
    </style>
  )
  const animateStyles = {
    animation: 'bounce 1.5s infinite',
  }

  const id = 'D3PHNFRaO7b9q2z1MiYk'
  const { fileData, loading } = useGetFileById('home', id)
  if (loading || fileData.imgUrl === undefined) {
    return <Loader />
  }

  if (!fileData || !fileData.imgUrl) {
    // Handle the case where fileData or imgUrl is null
    return <div>Error: File data not available</div>
  }

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Box
          sx={{
            backgroundImage: `url(${fileData.imgUrl})`,
            backgroundAttachment: 'fixed',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPositionX: 'center',

            width: '100vw',
            height: '100vh',
            pb: 10,
          }}
        >
          <Keyframes />
          <Fade in timeout={3000}>
            <Box
              textAlign="center"
              sx={{
                backgroundColor: 'rgba(0,0,0,0.5)',
                width: '100vw',
                height: '100vh',
                backgroundRepeat: 'no-repeat',
                backgroundPositionX: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-end',
                }}
              >
                {' '}
                <Typography
                  variant="h1"
                  color="text.secondary"
                  sx={{
                    fontFamily: 'Sacramento',
                    fontSize: { xs: '5rem', sm: '6rem' },
                    opacity: '1',
                  }}
                >
                  Isa Kamera
                </Typography>
                <Typography
                  variant="h1"
                  color="#ffffff"
                  gutterBottom
                  sx={{
                    fontFamily: 'Afacad',
                    fontSize: { xs: '1.5rem', sm: '2rem' },
                    opacity: '1',
                    mt: { xs: -2, sm: -4 },
                  }}
                >
                  {' '}
                  by Isabel Botello{' '}
                </Typography>
              </Box>
              <Box
                sx={{
                  height: '2px',
                  width: {
                    xs: '300px',
                    sm: '500px',
                    md: '800px',
                    lg: '1000px',
                  },
                  backgroundColor: 'text.secondary',
                  animation: ' expandAnimation 5s ease',
                }}
              ></Box>
              <Fade in timeout={{ appear: 10000, enter: 50 }}>
                <Typography
                  color="#ffffff"
                  variant="overline"
                  sx={{
                    fontSize: { xs: '1rem', sm: '1.2rem' },
                    fontFamily: 'Afacad',
                    mt: 4,
                  }}
                >
                  Your nearby Food and Portrait Photographer
                </Typography>
              </Fade>
              <Fade in timeout={7000}>
                <IconButton
                  component={Link}
                  to="/portfolio"
                  sx={{
                    color: '#ffffff',
                    border: { xs: 'none', md: '2px solid #ffffff' },
                    borderRadius: '0',
                    my: 4,
                    px: 4,
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      fontFamily: 'Afacad',
                      '&:hover': { opacity: '0.5' },
                      transition: '0.5s',
                      fontSize: { xs: '1.5rem', sm: '1.3rem' },
                    }}
                  >
                    See more
                  </Typography>
                  <KeyboardDoubleArrowRightOutlinedIcon
                    sx={{
                      ...animateStyles,
                      fontSize: { xs: '2rem', sm: '3rem' },
                      '&:hover': { opacity: '0.5' },
                      transition: '0.5s',
                    }}
                  />
                </IconButton>
              </Fade>
            </Box>
          </Fade>
        </Box>
      )}
    </>
  )
}

export default ViewHome
