import React from 'react'

import ViewHome from '../components/home/ViewHome'
import { motion } from 'framer-motion'

const Home = () => {
  return (
    <motion.div
      initial={{ opacity: 0, transition: { duration: 1 } }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, transition: { duration: 1 } }}
    >
      <ViewHome />
    </motion.div>
  )
}

export default Home
