import { ref, deleteObject } from 'firebase/storage'
import { storage } from '../../firebase-config'

const useDeleteImage = () => {
  const deleteImage = async (path) => {
    try {
      if (!path) {
        console.error('Invalid file path.')
        return
      }

      const storageRef = ref(storage, path)
      await deleteObject(storageRef)
      console.log('File deleted successfully.')
    } catch (error) {
      console.error('Error deleting file:', error)
    }
  }

  return { deleteImage }
}

export default useDeleteImage
