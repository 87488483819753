import React, { useState } from 'react'

import useDialog from './hooks/useDialog'
import { Dialog } from '@mui/material'

const Photo = ({ src, alt }) => {
  const { isOpen, openDialog, closeDialog } = useDialog()

  const [isHovered, setHovered] = useState(false)
  const [isClicked, setClicked] = useState(false)
  const handleMouseEnter = () => {
    setHovered(true)
  }

  const handleMouseLeave = () => {
    setHovered(false)
  }
  const imageStyle = {
    transition: 'transform 0.5s ease-in-out, opacity 0.3s ease-in-out',
    transform: isClicked ? 'scale(1.1)' : isHovered ? 'scale(0.9)' : 'scale(1)',
    opacity: isClicked ? 0.7 : 1,
  }
  const handleOnClick = () => {
    setClicked(true)
    openDialog()
    setTimeout(() => {
      setClicked(false)
    }, 500)
  }

  return (
    <>
      <img
        srcSet={`${src}?w=161&fit=crop&auto=format&dpr=2 2x`}
        src={`${src}?w=161&fit=crop&auto=format`}
        alt={alt}
        loading="eager"
        onClick={handleOnClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{
          ...imageStyle,
          objectFit: 'cover',
          width: '100%',
          height: '100%',
          display: 'block',
        }}
      />

      <Dialog
        onClose={closeDialog}
        open={isOpen}
        sx={{ minWidth: '100%', minHeight: '100%' }}
      >
        <img
          src={src}
          alt={`${alt} zoomed`}
          style={{ width: 'auto', height: '100%' }}
        />
      </Dialog>
    </>
  )
}

export default Photo
