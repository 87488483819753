import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'

import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { themeOptions } from './components/utils'
import { AuthProvider } from './context/AuthContext'

import { IdProvider } from './context/IdContext'
import { ScrollProvider } from './context/ScrollContext'
import AnimatedRoutes from './components/AnimatedRoutes'

const App = () => {
  const theme = createTheme(themeOptions)
  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline>
          <Router>
            <IdProvider>
              <ScrollProvider>
                <AnimatedRoutes />
              </ScrollProvider>
            </IdProvider>
          </Router>
        </CssBaseline>
      </ThemeProvider>
    </AuthProvider>
  )
}

export default App
