import { Box } from '@mui/material'
import React from 'react'
import useGetFileById from '../hooks/useGetFileById'
import Loader from '../Loader'
import { Fade, Slide, Typography } from '@mui/material'
import InstagramBlock from './InstagramBlock'
const ViewAbout = () => {
  const id = 'SW1j752UofxFrIdt3g8h'
  const { fileData, loading } = useGetFileById('about', id)
  const profileId = 'r4s1FhAAXbwJ2RbflO0N'
  const { fileData: profileData, loading: profileLoading } = useGetFileById(
    'profile-photo',
    profileId,
  )
  if (loading || fileData.imgUrl === undefined) {
    return <Loader />
  }

  if (!fileData || !fileData.imgUrl) {
    // Handle the case where fileData or imgUrl is null
    return <div>Error: File data not available</div>
  }

  if (profileLoading || profileData.imgUrl === undefined) {
    return <Loader />
  }

  if (!profileData || !profileData.imgUrl) {
    // Handle the case where fileData or imgUrl is null
    return <div>Error: File data not available</div>
  }

  const Keyframes = () => (
    <style>
      {`
        @keyframes bounce {
          0%, 20%, 50%, 80%, 100% {
            transform: translateX(0);
          }
          40% {
            transform: translateX(30px);
          }
          60% {
            transform: translateX(15px);
          }
        }

        @keyframes expandAnimation {
          0% {
            transform: scale(0);
          }
          100% {
            transform: scale(1);
          }
        }
      `}
    </style>
  )

  return (
    <>
      <Keyframes />

      <Box
        sx={{
          width: '100vw',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'left',
          flexDirection: 'column',
          mt: { xs: '100px', sm: '150px' },
          mb: { xs: 10, sm: 5 },
        }}
      >
        {' '}
        {/* Expanding line */}
        <Box
          textAlign="center"
          sx={{
            height: '1px',
            width: {
              xs: '400px',
              sm: '600px',
              md: '800px',
              lg: '1200px',
              xl: '1400px',
            },
            mb: 1,
            backgroundColor: '#000000',
            animation: ' expandAnimation 1s ease',
            mt: { xs: 3, sm: 5 },
          }}
        ></Box>{' '}
        <Fade in timeout={2000}>
          <Box textAlign="center">
            <Typography
              variant="h6"
              sx={{
                fontFamily: 'Afacad',
                display: { xs: 'none', sm: 'block' },
                my: 1,
                fontSize: '0.9rem',
              }}
            >
              Elevating Flavor, Capturing Moments.
            </Typography>
            <Typography
              variant="h3"
              sx={{ fontSize: { xs: '1.5rem' }, mx: 6, my: 4 }}
            >
              I'm passionate about unveiling the inherent beauty of food!
            </Typography>{' '}
            <Box
              sx={{
                width: { xs: '90vw', md: '80vw', lg: '60vw' },
                mt: 3,
                height: { xs: '200px', md: '300px', xl: '400px' },
                objectFit: 'cover',
              }}
            >
              <img
                src={fileData.imgUrl}
                alt="hero-contact-page"
                width="100%"
                height="100%"
                style={{ objectFit: 'cover' }}
              />
            </Box>
          </Box>
        </Fade>{' '}
        <Box
          sx={{
            display: 'flex',
            alignItems: { xs: 'center', md: 'start' },
            justifyContent: 'center',
            flexDirection: { xs: 'column', md: 'row' },
            mt: { xs: 15, sm: 20, xl: 15 },
          }}
        >
          <Slide in direction="right" timeout={{ appear: 100000, enter: 2000 }}>
            <Box
              sx={{
                width: {
                  xs: '50vw',
                  md: '40vw',
                  lg: '30vw',
                  xl: '20vw',
                },
                objectFit: 'cover',
              }}
            >
              <img
                src={profileData.imgUrl}
                alt="profile pic"
                width="100%"
                height="100%"
              />
            </Box>
          </Slide>
          <Slide in direction="left" timeout={{ appear: 100000, enter: 2000 }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'start',
                justifyContent: 'center',
                flexDirection: { xs: 'column', md: 'column' },
                width: {
                  xs: '70vw',
                  md: '40vw',
                  lg: '30vw',
                },
                mt: { xs: 10, md: 0 },
                mb: 10,
              }}
            >
              <Typography
                variant="h3"
                sx={{
                  fontSize: { xs: '2rem', sm: '1.5rem' },
                  mx: { xs: 0, sm: 6 },
                }}
                gutterBottom
              >
                Isabel Botello
              </Typography>
              <Typography
                sx={{
                  fontFamily: 'Afacad',
                  fontSize: { xs: '1.5rem', sm: '1rem' },
                  mx: { xs: 0, sm: 6 },
                }}
                gutterBottom
              >
                Photographer
              </Typography>
              <Typography
                sx={{ mx: { xs: 0, sm: 6 } }}
                gutterBottom
                textAlign="justify"
              >
                Hello, I'm Isabel, a Spanish food photographer based in New
                Zealand. Operating mainly from my home studio, I specialize in
                creating genuine and vibrant images that showcase the inherent
                beauty of food. Constantly exploring new styles, I enjoy
                challenging myself to evolve in the field.
              </Typography>
              <Typography
                sx={{ mx: { xs: 0, sm: 6 } }}
                gutterBottom
                textAlign="justify"
              >
                In my earlier years, while traveling, I found joy in portrait
                and macro photography. As a mom, I transitioned to part-time
                work, dedicating myself to learning the ins and outs of food
                photography through hands-on experimentation.
              </Typography>
              <Typography
                sx={{ mx: { xs: 0, sm: 6 } }}
                gutterBottom
                textAlign="justify"
              >
                I approach each shoot day with genuine enthusiasm, aiming to
                bring out the natural allure of food. My ultimate goal is to
                evoke in viewers the same excitement I feel for the culinary
                subjects. Collaborating with clients on diverse projects is a
                source of joy for me, as each job presents a unique opportunity.
              </Typography>
              <Typography
                sx={{ mx: { xs: 0, sm: 6 } }}
                gutterBottom
                textAlign="justify"
              >
                If you're interested in working together, I'd love to hear from
                you. Let's discuss your project! Feel free to email me at &nbsp;
                <a
                  href="mailto:isakamera.contact@gmail.com"
                  style={{
                    color: '#000000',
                    fontWeight: 'bolder',
                  }}
                >
                  isakamer.contact@gmail.com
                </a>
              </Typography>
            </Box>
          </Slide>
        </Box>
        <InstagramBlock />
      </Box>
    </>
  )
}

export default ViewAbout
