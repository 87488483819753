import { useState } from 'react'
import { collection, addDoc } from 'firebase/firestore'
import { db } from '../../../firebase-config'
const useCreate = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const createDocument = async (collectionName, data) => {
    try {
      setLoading(true)

      const collectionRef = collection(db, collectionName)
      console.log('Collection Reference:', collectionRef)

      const docRef = await addDoc(collectionRef, data)
      console.log('Document Reference:', docRef)

      setLoading(false)
      console.log('Data created:', data)
      return docRef.id
    } catch (error) {
      console.error('Error creating document:', error)
      setLoading(false)
      setError('Error creating document')
    }
  }
  return { createDocument, loading, error }
}

export default useCreate
