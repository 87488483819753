import { Container, TextField, Button, Paper, Typography } from '@mui/material'

import { useInput } from '../components/hooks/useInput'

import { useState } from 'react'
import { useAuth } from '../context/AuthContext'
import { useNavigate } from 'react-router-dom'
const Login = () => {
  const [email, clearEmail] = useInput('')
  const [password, clearPassword] = useInput('')

  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  const { signIn, currentUser, logOut } = useAuth()

  const navigate = useNavigate()

  const handleSumbit = async (e) => {
    e.preventDefault()

    setError(null)
    setLoading(true)
    try {
      await signIn(email.value, password.value)
      navigate('/')
    } catch {
      setError('An error occurred during login. Please try again.')
    }
    setLoading(false)
    clearEmail()
    clearPassword()
  }

  return (
    <Container
      sx={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {!currentUser ? (
        <Paper
          elevation={1}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            maxWidth: '400px',
            p: 3,
            borderRadius: '10px',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography variant="h5">Admin Login</Typography>
          <TextField
            {...email}
            label="Email"
            type="email"
            required
            sx={{ my: 3, minWidth: '300px' }}
          ></TextField>
          <TextField
            {...password}
            label="Password"
            type="password"
            required
            sx={{ minWidth: '300px' }}
          ></TextField>
          <Button
            variant="outlined"
            disabled={loading}
            onClick={handleSumbit}
            color="secondary"
            sx={{ my: 3, width: '100px' }}
          >
            Login
          </Button>
          {error && <p style={{ color: 'red' }}>{error}</p>}
        </Paper>
      ) : (
        <Container
          sx={{
            width: '100vw',
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <Typography variant="h5">Hi Isabell!</Typography>
          <Button
            variant="outlined"
            disabled={loading}
            onClick={logOut}
            color="secondary"
            sx={{ my: 3, width: '100px' }}
          >
            Logout
          </Button>
        </Container>
      )}
    </Container>
  )
}

export default Login
