import React from 'react'
import ViewAbout from '../components/about/ViewAbout'
import { motion } from 'framer-motion'
const About = () => {
  return (
    <motion.div
      initial={{ opacity: 0, transition: { duration: 1 } }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, transition: { duration: 1 } }}
    >
      <ViewAbout />
    </motion.div>
  )
}

export default About
