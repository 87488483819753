import { Button, TextField, Box } from '@mui/material'
import React, { useState } from 'react'
import Field from './Field'

const EnquiryForm = () => {
  const [name, setName] = useState('')
  const [subject, setSubject] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [message, setMessage] = useState('')

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          mb: 3,
        }}
      >
        <form
          target="_self"
          action="https://formsubmit.co/isakamera.contact@gmail.com"
          method="POST"
        >
          <Field
            label="Your Name"
            required
            type="text"
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="May I please know your name?"
          />

          <Field
            label="Phone Number"
            required
            type="tel"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            name="tel"
            placeholder="Your phone number for future contact"
          />

          <Field
            label="Email"
            required
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            name="email"
            placeholder="Your email for future contact"
          />

          <Field
            label="Subject"
            required
            type="text"
            name="subject"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            placeholder="Let me know what your inquiry is about"
          />

          <TextField
            label="Message"
            margin="normal"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            type="text"
            name="message"
            multiline={true}
            fullWidth
            minRows={10}
            placeholder="Give us some more details if you can"
            sx={{ backgroundColor: '#ffffff', opacity: '0.9' }}
          />

          <Button
            type="submit"
            variant="contained"
            color="secondary"
            sx={{ mt: { xs: 2, sm: 2, md: 5, fontWeight: 'bolder' } }}
          >
            Send Message
          </Button>

          <input
            type="hidden"
            name="_subject"
            value={`New enquiry from ${name}`}
          ></input>
          <input
            type="hidden"
            name="_autoresponse"
            value={`Dear ${name},Thanks for contacting me regarding "${subject}". Please bare with me, I will get back to you as soon as possible. Regards, Isa`}
          ></input>

          {/* <input type="hidden" name="_url" value="https://yourdomain.co/contact.html"></input> */}
        </form>
      </Box>
    </>
  )
}

export default EnquiryForm
