import React, { useState, useRef } from 'react'
import useCreate from './hooks/CRUD/useCreate'
import useImageUpload from './hooks/useImageUpload'
import useDeleteImage from './hooks/useDeleteImage'
import {
  Box,
  Typography,
  TextField,
  Dialog,
  DialogContent,
  IconButton,
  Tooltip,
  Paper,
} from '@mui/material'
import upload from '../assets/upload.png'
import LinearBuffer from './LinearBuffer'
import useAutoCloseDialog from './hooks/useAutoCloseDialog'
import { Done, Undo } from '@mui/icons-material'
import useRead from './hooks/CRUD/useRead'
//component start
const NewUpload = ({ folder, onSubmit }) => {
  const {
    imageUrl,
    uploadProgress,
    selectedImage,
    error,
    handleFileChangeAndUpload,
    setSelectedImage,
  } = useImageUpload()
  const { createDocument } = useCreate()
  const { deleteImage } = useDeleteImage()
  const { fetchData } = useRead(folder)
  const [order, setOrder] = useState('')
  const [desc, setDesc] = useState('')
  const [oldImageUrl, setOldImageUrl] = useState(null)
  const {
    isDialogVisible: isUploadDialogVisible,
    openDialog: openUploadDialog,
    closeDialog: closeUploadDialog,
  } = useAutoCloseDialog(false, 1000)
  const {
    isDialogVisible: isCancelDialogVisible,
    openDialog: openCancelDialog,
    closeDialog: closeCancelDialog,
  } = useAutoCloseDialog(false, 1000)
  const inputRef = useRef(null)

  const handleSubmit = async () => {
    const data = {
      order: order,
      imgUrl: imageUrl,
      desc: desc,
    }

    try {
      await createDocument(folder, data)
      await fetchData()
      openUploadDialog()
      setOrder('')
      setDesc('')
      setSelectedImage(null)
    } catch (error) {
      console.error('Error creating document:', error)
    }
  }

  const handleImageUpload = async (e) => {
    e.preventDefault()

    await handleFileChangeAndUpload(e, folder)

    setOldImageUrl(imageUrl)
    console.log('imgUrl:', imageUrl)
  }

  const handleCancelCreate = () => {
    if (oldImageUrl !== imageUrl) {
      try {
        deleteImage(imageUrl)
        setSelectedImage(null)
        openCancelDialog()
      } catch (err) {
        console.error(err)
      }
    }
  }
  const handleUploadClick = async () => {
    inputRef.current.click()
  }

  const isUploading = uploadProgress !== 0 && uploadProgress !== 100

  return (
    <>
      <Paper
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: { xs: 'column', sm: 'row' },
          mt: 10,
          px: 2,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'left',
            justifyContent: 'center',
            flexDirection: 'column',
            mt: 4,
          }}
        >
          <Tooltip title="Choose a photo from file">
            <label htmlFor="upload-input">
              <input
                type="file"
                accept="image/*"
                ref={inputRef}
                onChange={handleImageUpload}
                style={{ display: 'none' }}
              />

              <Box
                sx={{
                  width: '150px',
                  height: '150px',
                  overflow: 'hidden',
                  position: 'relative',
                  cursor: 'pointer',
                }}
              >
                {selectedImage ? (
                  <img
                    src={selectedImage}
                    alt="Preview"
                    marign="-100px 0px 0px -100px"
                    height="100px"
                    display="block"
                    onClick={handleUploadClick}
                  />
                ) : (
                  <img
                    src={upload}
                    alt="upload"
                    marign="-100px 0px 0px -100px"
                    height="120px"
                    display="block"
                    onClick={handleUploadClick}
                  />
                )}{' '}
                {uploadProgress > 0 && (
                  <LinearBuffer uploadProgress={uploadProgress} />
                )}
                {error && <Typography>{error}</Typography>}
              </Box>
            </label>
          </Tooltip>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: { xs: 'column', sm: 'row' },
          }}
        >
          <TextField
            type="number"
            label="Order"
            value={order}
            onChange={(e) => setOrder(e.target.value.replace(/[^0-9]/g, ''))}
            size="small"
            sx={{
              mx: 2,
              mt: { xs: 3, sm: 4 },
              width: { xs: '80%', sm: '80px' },
            }}
          />
          <TextField
            type="text"
            label="Description"
            placeholder="Add a description"
            value={desc}
            onChange={(e) => setDesc(e.target.value)}
            size="small"
            sx={{
              mx: 2,
              mt: { xs: 3, sm: 4 },
              width: { xs: '80%', sm: '200px' },
            }}
          />
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <Tooltip title="Undo Create">
              <IconButton onClick={handleCancelCreate}>
                <Undo sx={{ color: '#E5C262' }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Upload Photo">
              <IconButton
                disabled={uploadProgress !== 0 && uploadProgress !== 100}
                onClick={handleSubmit}
              >
                <Done
                  sx={isUploading ? { color: '#c7c7c7' } : { color: '#E5C262' }}
                />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </Paper>
      <Dialog open={isUploadDialogVisible} onClose={closeUploadDialog}>
        <DialogContent>
          <Typography variant="h6">
            New photo is uploaded successfully!
          </Typography>
        </DialogContent>
      </Dialog>
      <Dialog open={isCancelDialogVisible} onClose={closeCancelDialog}>
        <DialogContent>
          <Typography variant="h6">Upload Cancelled</Typography>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default NewUpload
