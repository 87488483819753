import { createContext, useContext, useState, useEffect } from 'react'

const IdContext = createContext()

export const IdProvider = ({ children }) => {
  const [selectedItemId, setSelectedItemId] = useState(() => {
    const storedId = localStorage.getItem('selectedItemId')
    return storedId || null
  })

  const setId = (itemId) => {
    setSelectedItemId(itemId)
  }

  useEffect(() => {
    localStorage.setItem('selectedItemId', selectedItemId)
    console.log('ID is saved')
  }, [selectedItemId])

  return (
    <IdContext.Provider value={{ selectedItemId, setId }}>
      {children}
    </IdContext.Provider>
  )
}

export const useItemId = () => {
  const context = useContext(IdContext)
  if (!context) {
    throw new Error('useItem must be used within an ItemProvider')
  }
  return context
}
