import React from 'react'
import { Box, IconButton, Tooltip, Typography } from '@mui/material'
import { useAuth } from '../context/AuthContext'
import { Edit } from '@mui/icons-material'
import { useLocation } from 'react-router-dom'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import useDialog from './hooks/useDialog'
import EditFood from './food/EditFood'
import EditPortfolio from './portfolio/EditPortfolio'
import EditPortrait from './portraits/EditPortrait'
import Popup from './Popup'
import EditAbout from './about/EditAbout'
import EditContact from './Contact/EditContact'
import EditHome from './home/EditHome'
import ContactBlock from './ContactBlock'

const Footer = () => {
  const { currentUser, logOut } = useAuth()
  const year = new Date().getFullYear()
  const location = useLocation()
  const isPortfolio = location.pathname === '/portfolio'
  const isFood = location.pathname === '/portfolio/food'
  const isPortrait = location.pathname === '/portfolio/portrait'
  const isAbout = location.pathname === '/about'
  const isContact = location.pathname === '/contact'
  const isHome = location.pathname === '/'

  //For Edit Home Popup
  const {
    isOpen: isHomeOpen,
    openDialog: openHomeDialog,
    closeDialog: closeHomeDialog,
  } = useDialog()
  const handleEditHomeButtonClick = (e) => {
    e.preventDefault()
    openHomeDialog()
  }

  const handleCloseHomeDialog = () => {
    closeHomeDialog()
  }

  //for Edit portfolio popup
  const {
    isOpen: isPortfolioOpen,
    openDialog: openPortfolioDialog,
    closeDialog: closePortfolioDialog,
  } = useDialog()
  const handleEditPortfolioButtonClick = (e) => {
    e.preventDefault()
    openPortfolioDialog()
  }

  const handleClosePortfolioDialog = () => {
    closePortfolioDialog()
  }

  //for Edit Food popup

  const {
    isOpen: isFoodOpen,
    openDialog: openFoodDialog,
    closeDialog: closeFoodDialog,
  } = useDialog()
  const handleEditFoodButtonClick = (e) => {
    e.preventDefault()
    openFoodDialog()
  }

  const handleCloseFoodDialog = () => {
    closeFoodDialog()
  }

  //for Edit Portrait popup

  const {
    isOpen: isPortraitOpen,
    openDialog: openPortraitDialog,
    closeDialog: closePortraitDialog,
  } = useDialog()

  const handleEditPortraitButtonClick = (e) => {
    e.preventDefault()
    openPortraitDialog()
  }

  const handleClosePortraitDialog = () => {
    closePortraitDialog()
  }
  // For Edit About popup
  const {
    isOpen: isAboutOpen,
    openDialog: openAboutDialog,
    closeDialog: closeAboutDialog,
  } = useDialog()
  const handleEditAboutButtonClick = (e) => {
    e.preventDefault()
    openAboutDialog()
  }

  const handleCloseAboutDialog = () => {
    closeAboutDialog()
  }

  // For Edit Contact popup
  const {
    isOpen: isContactOpen,
    openDialog: openContactDialog,
    closeDialog: closeContactDialog,
  } = useDialog()
  const handleEditContactButtonClick = (e) => {
    e.preventDefault()
    openContactDialog()
  }

  const handleCloseContactDialog = () => {
    closeContactDialog()
  }

  const boxStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: (isHome || isContact) && 'fixed',
    bottom: (isHome || isContact) && 0,
    left: (isHome || isContact) && 0,
    mt: 2,
    width: '100%',
    pb: 2,
    backgroundColor: 'secondary',
  }
  return (
    <>
      <Box
        sx={{
          ...boxStyle,
          backgroundColor: isPortrait || isFood || isAbout ? '#000000' : null,
        }}
      >
        {(isPortrait || isFood || isAbout) && <ContactBlock />}
        {currentUser && (
          <Box sx={{ display: 'flex' }}>
            <Tooltip title="Edit page">
              {isPortfolio && (
                <IconButton
                  color="secondary"
                  onClick={handleEditPortfolioButtonClick}
                  variant="outlined"
                  sx={{
                    cursor: 'pointer',

                    fontSize: '1rem',
                  }}
                >
                  <Edit sx={{ fontSize: { xs: '1rem', sm: '2rem' } }} />
                </IconButton>
              )}
              {isHome && (
                <IconButton
                  color="secondary"
                  onClick={handleEditHomeButtonClick}
                  variant="outlined"
                  sx={{
                    cursor: 'pointer',

                    fontSize: '1rem',
                  }}
                >
                  <Edit sx={{ fontSize: { xs: '1rem', sm: '2rem' } }} />
                </IconButton>
              )}
              {isFood && (
                <IconButton
                  color="secondary"
                  onClick={handleEditFoodButtonClick}
                  variant="outlined"
                  sx={{
                    cursor: 'pointer',

                    fontSize: '1rem',
                  }}
                >
                  <Edit sx={{ fontSize: { xs: '1rem', sm: '2rem' } }} />
                </IconButton>
              )}
              {isPortrait && (
                <IconButton
                  color="secondary"
                  onClick={handleEditPortraitButtonClick}
                  variant="outlined"
                  sx={{
                    cursor: 'pointer',

                    fontSize: '1rem',
                  }}
                >
                  <Edit sx={{ fontSize: { xs: '1rem', sm: '2rem' } }} />
                </IconButton>
              )}
              {isAbout && (
                <IconButton
                  color="secondary"
                  onClick={handleEditAboutButtonClick}
                  variant="outlined"
                  sx={{
                    cursor: 'pointer',

                    fontSize: '1rem',
                  }}
                >
                  <Edit sx={{ fontSize: { xs: '1rem', sm: '2rem' } }} />
                </IconButton>
              )}
              {isContact && (
                <IconButton
                  color="secondary"
                  onClick={handleEditContactButtonClick}
                  variant="outlined"
                  sx={{
                    cursor: 'pointer',

                    fontSize: '1rem',
                  }}
                >
                  <Edit sx={{ fontSize: { xs: '1rem', sm: '2rem' } }} />
                </IconButton>
              )}
            </Tooltip>
            <Tooltip title="Sign out">
              <IconButton
                color="secondary"
                onClick={logOut}
                variant="outlined"
                sx={{ cursor: 'pointer' }}
              >
                <ExitToAppIcon sx={{ fontSize: { xs: '1rem', sm: '2rem' } }} />
              </IconButton>
            </Tooltip>
          </Box>
        )}

        <Typography
          variant="h3"
          sx={{
            color: !isContact && 'white',
            fontSize: '0.8rem',
            mt: 2,
          }}
        >
          Copyright © IsaKamera {year}
        </Typography>
      </Box>
      <Popup
        children={<EditPortfolio />}
        description={
          <>
            <Typography color="#000000">
              1.Click photo to choose photo from file.
            </Typography>
            <Typography color="#000000">
              2.Wait until it fully uploaded as showed by the progress bar.
            </Typography>
            <Typography color="#000000">3.Click "✔" to submit.</Typography>
            <Typography color="#000000">4.Click "↶" to cancel.</Typography>
            <Typography color="#000000">
              5.Press "Done" button at the bottom to exit.
            </Typography>
          </>
        }
        onClick={closePortfolioDialog}
        open={isPortfolioOpen}
        onClose={handleClosePortfolioDialog}
        title={
          <Typography variant="h3" sx={{ fontWeight: '400' }}>
            Edit Portfolio Hero Photos
          </Typography>
        }
      />
      <Popup
        children={<EditHome />}
        description={
          <>
            <Typography color="#000000">
              1.Click photo to choose photo from file.
            </Typography>
            <Typography color="#000000">
              2.Wait until it fully uploaded as showed by the progress bar.
            </Typography>
            <Typography color="#000000">3.Click "✔" to submit.</Typography>
            <Typography color="#000000">4.Click "↶" to cancel.</Typography>
            <Typography color="#000000">
              5.Press "Done" button at the bottom to exit.
            </Typography>
          </>
        }
        onClick={closeHomeDialog}
        open={isHomeOpen}
        onClose={handleCloseHomeDialog}
        title={
          <Typography variant="h3" sx={{ fontWeight: '400' }}>
            Edit Homepage Hero Photo
          </Typography>
        }
      />
      <Popup
        children={<EditFood />}
        description={
          <Typography>
            {' '}
            <>
              <Typography color="#000000">
                1.Click "+" to choose photo from file.
              </Typography>
              <Typography color="#000000">
                2.Wait until it fully uploaded as showed by the progress bar.
              </Typography>
              <Typography color="#000000">
                3.Optional: fill in order number to adjust photo display order.{' '}
              </Typography>
              <Typography color="#000000">
                4.Optional:fill in description.{' '}
              </Typography>
              <Typography color="#000000">5.Click "✔" to submit.</Typography>
              <Typography color="#000000">
                6.Always Click "↶" when wanting to cancel, so that to free cloud
                storage.
              </Typography>
              <Typography color="#000000">
                7.Press "Done" button at the bottom to exit.
              </Typography>
            </>
          </Typography>
        }
        onClick={closeFoodDialog}
        open={isFoodOpen}
        onClose={handleCloseFoodDialog}
        title={
          <Typography variant="h3" sx={{ fontWeight: '400' }}>
            Edit Food Photos
          </Typography>
        }
      />
      <Popup
        children={<EditPortrait />}
        description={
          <>
            <Typography color="#000000">
              1.Click "+" to choose photo from file.
            </Typography>
            <Typography color="#000000">
              2.Wait until it fully uploaded as showed by the progress bar.
            </Typography>
            <Typography color="#000000">
              3.Optional: fill in order number to adjust photo display order.{' '}
            </Typography>
            <Typography color="#000000">
              4.Optional:fill in description.{' '}
            </Typography>
            <Typography color="#000000">5.Click "✔" to submit.</Typography>
            <Typography color="#000000">
              6.Always Click "↶" when wanting to cancel, so that to free cloud
              storage.
            </Typography>
            <Typography color="#000000">
              7.Press "Done" button at the bottom to exit.
            </Typography>
          </>
        }
        onClick={closePortraitDialog}
        open={isPortraitOpen}
        onClose={handleClosePortraitDialog}
        title={
          <Typography variant="h3" sx={{ fontWeight: '400' }}>
            Edit Portrait Photos
          </Typography>
        }
      />
      <Popup
        children={<EditAbout />}
        description={<Typography>To be developing...</Typography>}
        onClick={closeAboutDialog}
        open={isAboutOpen}
        onClose={handleCloseAboutDialog}
        title={
          <Typography variant="h3" sx={{ fontWeight: '400' }}>
            Edit About page
          </Typography>
        }
      />
      <Popup
        children={<EditContact />}
        description={<Typography>To be developing...</Typography>}
        onClick={closeContactDialog}
        open={isContactOpen}
        onClose={handleCloseContactDialog}
        title={
          <Typography variant="h3" sx={{ fontWeight: '400' }}>
            Edit Contact page
          </Typography>
        }
      />
    </>
  )
}

export default Footer
