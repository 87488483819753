import { useState, useEffect } from 'react'
import { collection, getDocs } from 'firebase/firestore'
import { db } from '../../../firebase-config'

const useRead = (collectionName) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  const fetchData = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, collectionName))
      const documents = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }))
      const sortedData = documents.sort((a, b) => a.order - b.order)
      setData(sortedData)
      console.log(data)
    } catch (error) {
      console.error('Error reading documents:', error)
      setError('Error reading documents')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { data, loading, error, fetchData }
}

export default useRead
