import React, { useState, useEffect } from 'react'
import ImageList from '@mui/material/ImageList'
import ImageListItem from '@mui/material/ImageListItem'
import Photo from '../Photo'
import useRead from '../hooks/CRUD/useRead'
import { Box } from '@mui/system'
import { Typography } from '@mui/material'
import Loader from '../Loader'
import ContactButton from '../ContactButton'

const ViewPortraits = () => {
  const { data, loading } = useRead('portraits')
  const [cols, setCols] = useState(3)
  const { data: clientsData, clientsLoading } = useRead('clients')

  useEffect(() => {
    const handleResize = () => {
      // Adjust the number of columns based on the screen width
      if (window.innerWidth < 600) {
        setCols(2)
      } else if (window.innerWidth < 960) {
        setCols(2)
      } else if (window.innerWidth < 1900) {
        setCols(3)
      } else {
        setCols(4)
      }
    }

    // Initial adjustment on component mount
    handleResize()

    // Add event listener for window resize
    window.addEventListener('resize', handleResize)

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const Heading = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column' },
          alignItems: { xs: 'left' },
          justifyContent: { xs: 'center' },
          mt: { xs: 4, sm: 12 },
          mx: { xs: 6 },
          mb: { xs: 0, sm: 2 },
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'end' }}>
          <Typography
            variant="h3"
            sx={{ fontSize: { xs: '1rem', md: '1.5rem', lg: '2rem' } }}
          >
            Portfolio
          </Typography>
          <ContactButton />
        </Box>
        <Typography
          variant="h2"
          sx={{ fontSize: { xs: '2rem', md: '2.4rem', lg: '3rem' } }}
        >
          Portrait Photography
        </Typography>
      </Box>
    )
  }
  const Keyframes = () => (
    <style>
      {`
        @keyframes bounce {
          0%, 20%, 50%, 80%, 100% {
            transform: translateX(0);
          }
          40% {
            transform: translateX(30px);
          }
          60% {
            transform: translateX(15px);
          }
        }

        @keyframes expandAnimation {
          0% {
            transform: scale(0);
          }
          100% {
            transform: scale(1);
          }
        }
      `}
    </style>
  )
  if (loading || clientsLoading) return <Loader />
  return (
    <>
      <Box
        sx={{
          mt: { xs: 10, md: 12 },
          display: 'flex',
          flexDirection: { xs: 'column' },
          alignItems: 'center',
        }}
      >
        <Keyframes />{' '}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box
            textAlign="center"
            sx={{
              height: '1px',
              width: {
                xs: '400px',
                sm: '600px',
                md: '800px',
                lg: '1200px',
                xl: '1400px',
              },
              mb: 1,
              backgroundColor: '#000000',
              animation: ' expandAnimation 3s ease',
              mt: { xs: 3, sm: 5 },
            }}
          ></Box>{' '}
        </Box>
        <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
          <Heading />
        </Box>
        <ImageList
          sx={{
            width: { xs: '90vw', md: '90vw', xl: '80vw' },
            height: '100%',
            mt: { xs: 2, sm: 12 },
            mb: 10,

            // mx: { xs: 5, xl: 30 },
          }}
          variant="woven"
          cols={cols}
          gap={8}
        >
          {' '}
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            <Heading />
          </Box>
          {data.map((item) => (
            <ImageListItem key={item.id} sx={{ cursor: 'pointer' }}>
              <Photo src={item.imgUrl} alt={item.desc} />
            </ImageListItem>
          ))}
        </ImageList>{' '}
        <Box
          textAlign="center"
          sx={{
            height: '1px',
            width: {
              xs: '400px',
              sm: '600px',
              md: '800px',
              lg: '1200px',
              xl: '1400px',
            },
            mb: 1,
            backgroundColor: '#000000',
            animation: ' expandAnimation 3s ease',
            mt: { xs: 3, sm: 5 },
          }}
        ></Box>{' '}
        <Box
          sx={{
            my: 5,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '80vw',
            // mx: { xs: 2, sm: 4, md: 5 },
          }}
        >
          <Typography
            color="secondary"
            sx={{
              fontFamily: 'Afacad',
              fontSize: { xs: '1rem', md: '1.5rem' },
            }}
          >
            Proudly crafted commercial photography for:{' '}
          </Typography>
          <Box sx={{ display: 'flex', mb: 5, mt: 5 }}>
            {clientsData.map((item) => (
              <Box
                sx={{
                  width: '100px',
                  objectFit: 'cover',
                  mx: 1,
                  my: { xs: 1, sm: 0 },
                }}
              >
                <a href={item.desc} target="_blank" rel="noopener noreferrer">
                  <img
                    src={item.imgUrl}
                    alt={item.desc}
                    style={{
                      objectFit: 'cover',
                      width: '100%',
                      height: '100%',
                    }}
                  />
                </a>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default ViewPortraits
