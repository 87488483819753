import * as React from 'react'
import Box from '@mui/material/Box'
import LinearProgress from '@mui/material/LinearProgress'

export default function LinearBuffer({ uploadProgress }) {
  const [progress, setProgress] = React.useState(uploadProgress)
  const [buffer, setBuffer] = React.useState(10)

  const progressRef = React.useRef(() => {})
  React.useEffect(() => {
    progressRef.current = () => {
      if (progress > 100) {
        setProgress(0)
        setBuffer(10)
      } else {
        setProgress(uploadProgress)
        setBuffer(uploadProgress + 10)
      }
    }
  })

  React.useEffect(() => {
    const timer = setInterval(() => {
      progressRef.current()
    }, 500)

    return () => {
      clearInterval(timer)
    }
  }, [])

  return (
    <Box sx={{ width: '100%', mt: 1 }}>
      <LinearProgress
        variant="buffer"
        value={progress}
        valueBuffer={buffer}
        color="secondary"
      />
    </Box>
  )
}
