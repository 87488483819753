import React from 'react'
import useRead from '../hooks/CRUD/useRead'
import useDelete from '../hooks/CRUD/useDelete'
import { useItemId } from '../../context/IdContext'
import { useNavigate } from 'react-router-dom'
const Read = () => {
  /*HOOKS*/

  //initialize useNavigate hook
  const navigate = useNavigate()
  //initilaize useRead hook
  const { data } = useRead('testing')
  //initialize useDelete hook
  const { deleteDocument } = useDelete()
  //initialize useItemId hook for setId method
  const { setId } = useItemId()

  /*Functions*/

  //For delete Button
  const handleDelete = (id, imgUrl) => {
    //delete method from useDelete hook
    deleteDocument('testing', id, imgUrl)
    navigate('/testing')
  }

  //For Edit button
  const handNavigatToEdit = (id) => {
    //get and set file id for context
    setId(id)

    navigate('/testing/update')
  }

  return (
    <div>
      <a href="/testing/create">Add New</a>
      {data.map((item) => {
        return (
          <>
            <div key={item.id}>
              <img src={item.imgUrl} alt={item.title} width="300px" />
              <h4>{item.title}</h4>
              <button onClick={() => handNavigatToEdit(item.id)}>Edit</button>
              <button
                onClick={() => {
                  handleDelete(item.id, item.imgUrl)
                }}
              >
                Delete
              </button>
            </div>
          </>
        )
      })}
    </div>
  )
}

export default Read
