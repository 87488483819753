// ScrollContext.js
import React, { createContext, useContext, useState } from 'react'

const ScrollContext = createContext()

export const ScrollProvider = ({ children }) => {
  const [scrolled, setScrolled] = useState(false)

  return (
    <ScrollContext.Provider value={{ scrolled, setScrolled }}>
      {children}
    </ScrollContext.Provider>
  )
}

export const useScroll = () => {
  return useContext(ScrollContext)
}
