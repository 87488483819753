import React from 'react'
import { motion } from 'framer-motion'
import { Box, Typography } from '@mui/material'
import logo from '../assets/logo/logo_black.png'

const NotFound = () => {
  return (
    <motion.div
      initial={{ opacity: 0, transition: { duration: 1 } }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, transition: { duration: 1 } }}
    >
      <Box
        sx={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <img src={logo} alt="logo" width="200px" />
        <Typography variant="h4" sx={{ fontFamily: 'monospace', mt: 2 }}>
          404 NOT FOUND
        </Typography>
      </Box>
    </motion.div>
  )
}

export default NotFound
