import { IconButton, Tooltip } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

import { Message } from '@mui/icons-material'

const ContactButton = () => {
  const Keyframes = () => (
    <style>
      {`
          @keyframes blinkAnimation {
            0%, 50%, 100% {
              opacity: 1;
            }
            25%, 75% {
              opacity: 0;
            }
          }
        `}
    </style>
  )

  return (
    <>
      <Tooltip title="Contact me">
        <Keyframes />
        <IconButton
          component={Link}
          color="secondary"
          variant="outlined"
          sx={{
            textDecoration: 'none',
            fontSize: '0.8rem',
            animation: 'blinkAnimation 1.5s infinite',
          }}
          to="/contact"
        >
          <Message />
        </IconButton>
      </Tooltip>
    </>
  )
}

export default ContactButton
