import React from 'react'
import UpdateImage from '../UpdateImage'
import Loader from '../Loader'
import useRead from '../hooks/CRUD/useRead'
const EditContact = () => {
  const { data, loading } = useRead('contact')
  if (loading) return <Loader />
  return (
    <div>
      {data.map((item) => {
        return (
          <UpdateImage
            key={item.id}
            id={item.id}
            collectionName="contact"
            isUpdateImage={true}
            isEditText={false}
          />
        )
      })}
    </div>
  )
}

export default EditContact
