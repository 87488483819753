import { useState, useEffect } from 'react'

const useAutoCloseDialog = (initialState = false, autoCloseDelay = 5000) => {
  const [isDialogVisible, setIsDialogVisible] = useState(initialState)
  const [timer, setTimer] = useState(null)

  const openDialog = () => {
    setIsDialogVisible(true)
    setTimer(
      setTimeout(() => {
        setIsDialogVisible(false)
      }, autoCloseDelay),
    )
  }

  const closeDialog = () => {
    setIsDialogVisible(false)
    timer && clearTimeout(timer)
  }

  useEffect(() => {
    return () => {
      // Cleanup the timer on component unmount
      timer && clearTimeout(timer)
    }
  }, [timer])

  return { isDialogVisible, openDialog, closeDialog }
}

export default useAutoCloseDialog
