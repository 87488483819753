import { useState, useEffect } from 'react'
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage'
import { storage } from '../../firebase-config'
const useImageUpload = () => {
  const [ImageFile, setImageFile] = useState(null)
  const [imageUrl, setImageUrl] = useState(null)
  const [uploadProgress, setUploadProgress] = useState(0)
  const [error, setError] = useState(null)
  const [selectedImage, setSelectedImage] = useState(imageUrl)
  const [success, setSuccess] = useState(false)

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0]
    if (selectedFile) {
      setImageFile(selectedFile)

      const reader = new FileReader()

      reader.onload = (event) => {
        setSelectedImage(event.target.result)
      }

      reader.onerror = (error) => {
        console.error('Error reading the file:', error)
      }

      reader.readAsDataURL(selectedFile)
    } else {
      setImageFile(null)
      setImageUrl(null)
      setUploadProgress(0)
      setError(null)
      setSelectedImage(null)
    }
  }
  const handleUpload = async (folder) => {
    if (!ImageFile) {
      setError('Please select a file to upload.')
      return
    }

    const storageRef = ref(storage, `${folder}/${ImageFile.name}`)
    const uploadTask = uploadBytesResumable(storageRef, ImageFile)

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
        )
        setUploadProgress(progress)
      },
      (error) => {
        setError('Error uploading file: ' + error.message)
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref)
          .then((url) => {
            setImageUrl(url)
            setSuccess(true)
          })
          .catch((error) => {
            setError('Error getting download URL: ' + error.message)
          })
      },
    )
  }
  useEffect(() => {
    setSelectedImage(imageUrl)
  }, [imageUrl])

  /*USE THIS ONE*/
  //handle both file change and upload,including preview seleted file
  const handleFileChangeAndUpload = async (event, folder) => {
    const selectedFile = event.target.files[0]

    if (selectedFile) {
      setImageFile(selectedFile)

      const reader = new FileReader()

      reader.onload = (event) => {
        setSelectedImage(event.target.result)
      }

      reader.onerror = (error) => {
        console.error('Error reading the file:', error)
      }

      reader.readAsDataURL(selectedFile)

      // Upload logic
      const storageRef = ref(storage, `${folder}/${selectedFile.name}`)
      const uploadTask = uploadBytesResumable(storageRef, selectedFile)

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
          )
          setUploadProgress(progress)
        },
        (error) => {
          setError('Error uploading file: ' + error.message)
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref)
            .then((url) => {
              setImageUrl(url)
              setSuccess(true)
            })
            .catch((error) => {
              setError('Error getting download URL: ' + error.message)
            })
        },
      )
    } else {
      setImageFile(null)
      setImageUrl(null)
      setUploadProgress(0)
      setError(null)
      setSelectedImage(null)
    }
  }

  return {
    ImageFile,
    imageUrl,
    uploadProgress,
    error,
    selectedImage,
    success,
    handleFileChange,
    handleUpload,
    handleFileChangeAndUpload,
    setSelectedImage,
  }
}

export default useImageUpload
