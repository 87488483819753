import React from 'react'
import useRead from '../hooks/CRUD/useRead'
import { Box, Divider } from '@mui/material'
import Loader from '../Loader'
import NewUpload from '../NewUpload'
import UpdateImage from '../UpdateImage'
const EditFood = ({ onClick }) => {
  const { data, loading } = useRead('food')
  const { data: clientsData, clientsLoading } = useRead('clients')
  if (loading) return <Loader />
  if (clientsLoading) return <Loader />
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100vw',
        }}
      >
        <NewUpload folder="food" />
        <Box
          sx={{
            mt: 5,
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            flexWrap: { xs: 'nowrap', sm: 'wrap' },
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {data.map((image) => (
            <UpdateImage key={image.id} id={image.id} collectionName="food" />
          ))}
        </Box>
        <Divider variant="fullwidth" />

        <NewUpload folder="clients" />
        <Box
          sx={{
            mt: 5,
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            flexWrap: { xs: 'nowrap', sm: 'wrap' },
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {clientsData.map((image) => (
            <UpdateImage
              key={image.id}
              id={image.id}
              collectionName="clients"
            />
          ))}
        </Box>
      </Box>
    </>
  )
}

export default EditFood
