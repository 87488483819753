import React from 'react'

import { Box, Typography } from '@mui/material'
import logo from '../assets/logo/logo_black.png'
const Loader = () => {
  const Keyframes = () => (
    <style>
      {`
        @keyframes bounce {
          0%, 20%, 50%, 80%, 100% {
            transform: translateX(0);
          }
          40% {
            transform: translateX(30px);
          }
          60% {
            transform: translateX(15px);
          }
        }

        @keyframes expandAnimation {
          0% {
            transform: scale(0);
          }
          100% {
            transform: scale(1);
          }
        }
        @keyframes zoomInAndOut {
          0% {
            transform: scale(1);
          }
          40%{
            transform: scale(1.2);
          }
          60% {
            transform: scale(1.5);
          }
          100% {
            transform: scale(1.2);
          }
        }
      `}
    </style>
  )
  return (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <Keyframes />
      <img
        src={logo}
        alt="logo"
        width="60px"
        style={{ animation: 'zoomInAndOut 3s ease infinite alternate ' }}
      />
      <Typography
        sx={{
          mt: 2,
          animation: 'zoomInAndOut 3s ease  infinite alternate ',
          fontFamily: 'monospace',
        }}
      >
        Loading...
      </Typography>
    </Box>
  )
}

export default Loader
