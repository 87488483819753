import { useState } from 'react'
import { doc, deleteDoc, getDocs, collection } from 'firebase/firestore'
import { db, storage } from '../../../firebase-config'
import { ref, deleteObject } from 'firebase/storage'

const useDelete = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const getCollectionSize = async (collectionName) => {
    try {
      const querySnapshot = await getDocs(collection(db, collectionName))
      const documentCount = querySnapshot.size
      return documentCount
    } catch (error) {
      console.error('Error getting collection size:', error)
      return -1 // Return -1 to indicate an error
    }
  }

  const deleteDocument = async (collectionName, documentId, imageUrl) => {
    try {
      setLoading(true)

      const collectionSize = await getCollectionSize(collectionName)

      if (collectionSize > 1) {
        const imageRef = ref(storage, imageUrl)
        await deleteDoc(doc(db, collectionName, documentId))

        if (imageUrl !== null && imageUrl !== undefined) {
          await deleteObject(imageRef)
        }

        setLoading(false)
        console.log('Deleting success')
      } else {
        setError('You need to keep at least one photo on this page.')
      }
    } catch (error) {
      console.error('Error deleting document:', error)
      setLoading(false)
      setError('Error deleting document')
    }
  }

  return { deleteDocument, loading, error }
}

export default useDelete
