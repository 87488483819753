import React, { createContext, useContext, useState, useEffect } from 'react'
import { auth } from '../firebase-config'
import {
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
} from 'firebase/auth'

const AuthContext = createContext()

//use auth context hook
export const useAuth = () => {
  return useContext(AuthContext)
}

//Auth context provider
export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState()

  //sign in method
  const signIn = (email, password) => {
    signInWithEmailAndPassword(auth, email, password)
  }

  //sign out method
  const logOut = async () => {
    await signOut(auth)
    console.log('Admin Logged Out')
  }
  //monitor user state
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user)
      console.log('Auth state changed:', user)
    })

    return () => unsubscribe()
  }, [])
  //value return to context
  const value = { currentUser, signIn, logOut }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
