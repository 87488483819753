import React from 'react'
import useRead from '../hooks/CRUD/useRead'
import { Box } from '@mui/material'
import Loader from '../Loader'
import UpdateImage from '../UpdateImage'

const EditPortfolio = () => {
  const { data, loading } = useRead('portfolio')
  if (loading) return <Loader />
  return (
    <>
      <Box sx={{ mt: 20 }}>
        {data.map((image) => (
          <UpdateImage
            key={image.id}
            id={image.id}
            collectionName="portfolio"
            isUpdateImage={true}
            isEditText={false}
          />
        ))}
      </Box>
    </>
  )
}

export default EditPortfolio
