import { useState } from 'react'
import { doc, updateDoc, collection } from 'firebase/firestore'
import { db } from '../../../firebase-config'

const useUpdate = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const updateDocument = async (collectionName, documentId, data) => {
    try {
      setLoading(true)
      const collectionToUpdate = collection(db, collectionName)
      const itemRef = doc(collectionToUpdate, documentId)

      await updateDoc(itemRef, data)
      setLoading(false)
    } catch (error) {
      console.error('Error updating document:', error)
      setLoading(false)
      setError('Error updating document')
    }
  }

  return { updateDocument, loading, error }
}

export default useUpdate
