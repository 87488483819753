import React from 'react'
import { motion } from 'framer-motion'
import ViewFood from '../components/food/ViewFood'
const Food = () => {
  return (
    <motion.div
      initial={{ opacity: 0, transition: { duration: 1 } }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, transition: { duration: 1 } }}
    >
      <ViewFood />
    </motion.div>
  )
}

export default Food
