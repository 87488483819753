import React from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import Login from '../pages/Login'
import Layout from '../pages/Layout'
import Home from '../pages/Home'
import About from '../pages/About'
import NotFound from '../pages/NotFound'
import Contact from '../pages/Contact'
import Portfolio from '../pages/Portfolio'
import Food from '../pages/Food'
import Portrait from '../pages/Portrait'
import Read from '../components/testing/Read'
import Create from '../components/testing/Create'
import Update from '../components/testing/Update'
import { AnimatePresence } from 'framer-motion'
const AnimatedRoutes = () => {
  const location = useLocation()
  return (
    <AnimatePresence>
      <Routes location={location} key={location.pathname}>
        <Route path="/admin" element={<Login />} />
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/portfolio/food" element={<Food />} />
          <Route path="/portfolio/portrait" element={<Portrait />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/*" element={<NotFound />} />
          <Route path="/testing" element={<Read />} />
          <Route path="/testing/create" element={<Create />} />
          <Route path="/testing/update" element={<Update />} />
        </Route>
      </Routes>
    </AnimatePresence>
  )
}

export default AnimatedRoutes
