import React from 'react'
import { TextField } from '@mui/material'

const Field = ({
  label,
  type,
  name,
  value,
  onChange,
  placeholder,
  multiline = false,
}) => {
  return (
    <>
      <TextField
        fullWidth
        margin="normal"
        label={label}
        required
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        multiline={multiline}
        sx={{
          backgroundColor: '#ffffff',
          opacity: '0.9',
          borderColor: '#e5c262',
        }}
      />
    </>
  )
}

export default Field
