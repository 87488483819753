import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'
const firebaseConfig = {
  apiKey: 'AIzaSyDlCyzWWyuJfIUHICylhW8YfyQWFOQ5ks8',
  authDomain: 'isakamera-e3793.firebaseapp.com',
  projectId: 'isakamera-e3793',
  storageBucket: 'isakamera-e3793.appspot.com',
  messagingSenderId: '118937663141',
  appId: '1:118937663141:web:52c77f0884a167807c3f4c',
}

const app = initializeApp(firebaseConfig)
export const auth = getAuth(app)
export const db = getFirestore(app)
export const storage = getStorage(app)
