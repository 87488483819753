import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import React from 'react'
import { Button } from '@mui/material'

const Popup = ({ children, description, open, onClose, title }) => {
  return (
    <Dialog open={open} onClose={onClose} fullScreen sx={{ opacity: '0.98' }}>
      <Box sx={{ mt: 4, textAlign: 'center' }}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{description}</DialogContentText>
          {children}
          <Button
            variant="contained"
            color="secondary"
            onClick={onClose}
            size="large"
            sx={{
              mt: 2,
              width: '50vw',
              fontWeight: 'bolder',
              boxShadow: 'none',
            }}
          >
            Done
          </Button>
        </DialogContent>
      </Box>
    </Dialog>
  )
}

export default Popup
