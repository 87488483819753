import React from 'react'
import { Box, Typography, Fade, IconButton, Paper } from '@mui/material'

import EnquiryForm from './EnquiryForm'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import PhoneIcon from '@mui/icons-material/Phone'
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined'
import InstagramIcon from '@mui/icons-material/Instagram'
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail'

import logo from '../../assets/logo/logo_grey.png'
import useGetFileById from '../hooks/useGetFileById'
import Loader from '../Loader'
import { motion } from 'framer-motion'
const ViewContact = () => {
  const id = 'Ccm6jbFYUvx47pcmaOLG'
  const { fileData, loading } = useGetFileById('contact', id)

  // const imgUrl = fileData.imgUrl
  if (loading || fileData.imgUrl === undefined) {
    return <Loader />
  }

  if (!fileData || !fileData.imgUrl) {
    // Handle the case where fileData or imgUrl is null
    return <div>Error: File data not available</div>
  }

  return (
    <Fade in timeout={2000}>
      <Box
        sx={{
          width: '100vw',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'left',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            width: '100vw',
            height: '500px',
          }}
        >
          <img
            src={fileData.imgUrl}
            alt="hero-contact-page"
            width="100%"
            height="100%"
            style={{ objectFit: 'cover' }}
          />
        </Box>
        <motion.div
          initial={{ y: 400, transition: { duration: 5 } }}
          animate={{ y: 0 }}
          transition={{ duration: 3 }}
        >
          <Paper
            elevation={1}
            sx={{
              display: 'flex',
              flexDirection: 'column',

              alignItems: 'center',
              px: 10,
              pb: 10,
              mt: { xs: -20, sm: -30 },
            }}
          >
            <Typography
              variant="h3"
              textAlign="center"
              color="#000000"
              sx={{ my: 3, fontSize: { xs: '2rem', sm: '3rem' } }}
            >
              Contact Me
            </Typography>
            <img src={logo} alt="logo" width="40px" />
            <Box sx={{ mt: 1, width: { xs: '70vw', md: '60vw', lg: '50vw' } }}>
              <EnquiryForm />
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <LocationOnIcon sx={{ color: '#000000' }} />
              <Typography
                variant="body1"
                textAlign="center"
                color="#000000"
                gutterBottom
                sx={{ fontFamily: 'Afacad' }}
              >
                South Waikto, New Zealand
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <PhoneIcon sx={{ color: '#000000' }} />
              <Typography
                variant="body1"
                textAlign="center"
                color="#000000"
                sx={{ fontFamily: 'Afacad' }}
              >
                <a href="tel:+64220825163" style={{ color: '#000' }}>
                  +64 22 082 5163
                </a>
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                mt: 2,
              }}
            >
              <IconButton href="https://www.facebook.com/isabotell82">
                <FacebookOutlinedIcon sx={{ color: '#000000' }} />
              </IconButton>
              <IconButton href="https://www.instagram.com/isakamera11/">
                <InstagramIcon sx={{ color: '#000000' }} />
              </IconButton>
              <IconButton href="mailto:isakamera.contact@gmail.com">
                <AlternateEmailIcon sx={{ color: '#000000' }} />
              </IconButton>
            </Box>
          </Paper>
        </motion.div>
      </Box>
    </Fade>
  )
}

export default ViewContact
