export const navItems = [
  { item: 'Home', link: '/' },
  {
    item: 'Portfolio',
    link: '/portfolio',

    subitems: [
      { item: 'Food', link: '/portfolio/food' },
      { item: 'Portrait', link: '/portfolio/portrait' },
    ],
  },
  { item: 'About', link: '/about' },
  { item: 'Contact', link: '/contact' },
]

export const themeOptions = {
  palette: {
    type: 'light',
    primary: {
      main: '#ffffff',
      contrastText: '#c7c7c7',
      light: '#858482',
      dark: '#34343c',
    },
    secondary: {
      main: '#e5c262',
      dark: '#766b51',
    },
    text: {
      primary: '#020122',
      secondary: '#d6ac54',
      disabled: '#6c6c6d',
      hint: '#766b51',
    },
    background: {
      paper: '#fafafa',
      default: '#ffffff',
    },
    error: {
      main: '#c51e2d',
    },
    warning: {
      main: '#3B0D11',
    },
    info: {
      main: '#1f4805',
    },
    success: {
      main: '#ffd600',
    },
    divider: '#858482',
  },
  typography: {
    fontFamily: 'Lato',
    h5: {
      fontFamily: 'Montserrat',
    },
    h6: {
      fontFamily: 'Montserrat',
    },
    caption: {
      fontFamily: 'Abel',
    },
    button: {
      fontFamily: 'Lato',
    },
    h1: {
      fontFamily: 'Zeyada',
    },
    h2: {
      fontFamily: 'Montserrat',
    },
    h3: {
      fontFamily: 'Abril Fatface',
    },
    h4: {
      fontFamily: 'Montserrat',
    },
  },
}
