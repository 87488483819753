import React, { useState, useEffect } from 'react'
import { Link, NavLink } from 'react-router-dom'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import MenuIcon from '@mui/icons-material/Menu'
import Toolbar from '@mui/material/Toolbar'
import { Typography } from '@mui/material'
import Button from '@mui/material/Button'
import { Menu, Link as MuiLink } from '@mui/material'
import logo from '../assets/logo/logo_with_name_remove_bg_contrast.png'
import logoBlack from '../assets/logo/logo_with_name_removed_bg.png'
import logoColor from '../assets/logo/logo_colour.png'
import { navItems } from './utils.js'
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined'
import InstagramIcon from '@mui/icons-material/Instagram'
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail'

import { useLocation } from 'react-router-dom'

const drawerWidth = 240

const NavBar = ({ window }) => {
  const [mobileOpen, setMobileOpen] = useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState)
  }
  const location = useLocation()
  const isHome = location.pathname === '/'
  const isContact = location.pathname === '/contact'
  const isPortfolio = location.pathname === '/portfolio'

  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{
        backgroundColor: 'rgba(255,255,255,0.3)',
        height: '100%',
        pt: 2,
      }}
    >
      <Box textAlign="center">
        <img
          src={logoBlack}
          alt="logo"
          width="100px"
          style={{
            paddingLeft: '20px',
            paddingTop: '10px',
            paddingBottom: '10px',
          }}
        />
      </Box>

      <Divider sx={{ borderColor: '#c7c7c7', opacity: '1' }} variant="middle" />
      <List
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {navItems.map((item) => (
          <ListItem
            key={item.item}
            disablePadding
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <ListItemButton sx={{ textAlign: 'center' }}>
              <MuiLink component={Link} to={item.link}>
                <ListItemText
                  sx={{ color: 'text.secondary' }}
                  primary={item.item}
                  primaryTypographyProps={{
                    fontFamily: 'Afacad',
                    fontWeight: '400',
                  }}
                ></ListItemText>
              </MuiLink>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider sx={{ borderColor: '#c7c7c7' }} variant="middle" />
    </Box>
  )

  const container =
    window !== undefined ? () => window().document.body : undefined

  const [scrolled, setScrolled] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = document.documentElement.scrollTop > 0

      setScrolled(isScrolled)
    }

    document.addEventListener('scroll', handleScroll)

    return () => {
      document.removeEventListener('scroll', handleScroll)
    }
  }, [])

  // const { scrolled, setScrolled } = useScroll()
  // const isScrolled = useScrollTrigger()
  // useEffect(() => {
  //   setScrolled(isScrolled)
  // }, [isScrolled, scrolled, setScrolled])

  // useEffect(() => {
  //   console.log(scrolled)
  // }, [scrolled])

  const containerStyle = {
    transition: 'background-color 0.5s ease',
    backgroundColor: scrolled ? 'white' : 'transparent',
    opacity: '0.8',
  }

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <AppBar
          component="nav"
          sx={
            isHome
              ? {
                  boxShadow: 'none',
                  py: 1,
                  // border: '1px solid #000000',
                  backgroundColor: 'transparent',
                  px: { xs: 0, lg: 20, xl: 40 },
                }
              : {
                  ...containerStyle,
                  boxShadow: 'none',
                  py: 1,
                  // border: '1px solid #000000',
                  // backgroundColor: 'transparent',
                  px: { xs: 0, lg: 20, xl: 40 },
                }
          }
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: 'none' } }}
            >
              <MenuIcon />
            </IconButton>

            <Button href="/" sx={{ display: { xs: 'none', sm: 'block' } }}>
              {isHome || isPortfolio ? (
                <img src={logo} alt="logo" width="100px" height="auto" />
              ) : (
                <img src={logoBlack} alt="logo" width="100px" height="auto" />
              )}
            </Button>
            {!isHome && !isPortfolio && (
              <Box
                sx={{
                  display: { xs: 'flex', sm: 'none' },
                  alignItems: 'center',
                  ml: 2,
                  width: '100%',
                }}
              >
                <img src={logoColor} alt="logo" width="80px" />
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Typography
                    variant="h3"
                    sx={{
                      fontSize: '2rem',
                      ml: 2,
                      mb: -1,
                      color: '#000000',
                      mt: 1,
                      fontFamily: 'Sacramento',
                    }}
                  >
                    Isa Kamera
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      mb: -1,
                    }}
                  >
                    <IconButton href="https://www.facebook.com/isabotell82">
                      <FacebookOutlinedIcon sx={{ color: '#E5C262' }} />
                    </IconButton>
                    <IconButton href="https://www.instagram.com/isakamera11/">
                      <InstagramIcon sx={{ color: '#E5C262' }} />
                    </IconButton>
                    <IconButton href="mailto:isakamera.contact@gmail.com">
                      <AlternateEmailIcon sx={{ color: '#E5C262' }} />
                    </IconButton>
                  </Box>
                </Box>
              </Box>
            )}

            <Box
              sx={{
                display: { xs: 'none', sm: 'flex' },
                justifyContent: 'right',
                width: '100%',
                alignItems: 'center',
              }}
            >
              {navItems.map((item) => (
                <MuiLink
                  key={item.item}
                  to={item.item === 'Portfolio' ? null : item.link}
                  component={NavLink}
                  underline="none"
                >
                  <Button
                    key={item.item}
                    variant="h3"
                    onClick={item.item === 'Portfolio' ? handleClick : null}
                    sx={{
                      color: isHome || isPortfolio ? 'primary' : '#000000',
                      fontSize: '1rem',
                      fontFamily: 'Abril Fatface',
                      mx: { sm: -1, md: 0 },
                    }}
                  >
                    {item.item}
                  </Button>
                  {item.subitems && (
                    <Menu
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      sx={{
                        boxShadow: 'none',
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      {item.subitems.map((subitem) => (
                        <MuiLink
                          key={subitem.item}
                          to={subitem.link}
                          component={NavLink}
                          underline="none"
                        >
                          <Button
                            variant="h3"
                            sx={{
                              color: '#000000',
                              fontSize: '0.8rem',
                              fontFamily: 'Abril Fatface',
                              fontWeight: '100',
                              boxShadow: 'none',
                            }}
                            onClick={handleClose}
                          >
                            {subitem.item}
                          </Button>
                        </MuiLink>
                      ))}
                    </Menu>
                  )}
                </MuiLink>
              ))}
              {!isContact && (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <IconButton href="https://www.facebook.com/isabotell82">
                    <FacebookOutlinedIcon
                      sx={{ color: '#E5C262', fontSize: '1.2rem' }}
                    />
                  </IconButton>
                  <IconButton href="https://www.instagram.com/isakamera11/">
                    <InstagramIcon
                      sx={{ color: '#E5C262', fontSize: '1.2rem', mx: -3 }}
                    />
                  </IconButton>
                  <IconButton href="mailto:isakamera.contact@gmail.com">
                    <AlternateEmailIcon
                      sx={{ color: '#E5C262', fontSize: '1.2rem' }}
                    />
                  </IconButton>
                </Box>
              )}
            </Box>
          </Toolbar>
        </AppBar>
        <nav>
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': {
                boxSizing: 'border-box',
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
        </nav>
      </Box>
    </>
  )
}

export default NavBar
