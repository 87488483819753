import React, { useState } from 'react'
import useCreate from '../hooks/CRUD/useCreate'
import { useInput } from '../hooks/useInput'
import useImageUpload from '../hooks/useImageUpload'
import { useNavigate } from 'react-router-dom'
import useDeleteImage from '../hooks/useDeleteImage'

const Create = () => {
  /*HOOKS*/
  //initialize useImageUpload hook
  const {
    imageUrl,
    uploadProgress,
    selectedImage,
    error,
    handleFileChangeAndUpload,
  } = useImageUpload()
  //initialize useCreate hook
  const { createDocument } = useCreate()
  //initialize useNavigate hook
  const navigate = useNavigate()
  //initialize useInput hook
  const [title] = useInput('')

  /*STATES*/
  //initialize useDelete hook
  const { deleteImage } = useDeleteImage()
  //initialize varible to store old url
  const [oldImageUrl, setOldImageUrl] = useState(null)

  /*Functions*/

  //For submit button onClick
  const handleSubmit = async () => {
    const data = {
      title: title.value,
      imgUrl: imageUrl,
    }

    //create method from useCreate hook
    await createDocument('testing', data)
    console.log('data created:', data)
    navigate('/testing')
  }
  //For upload button onClick
  const handleImageUpload = async (e) => {
    //onchange and upload method from useImageUpload,taking event an
    await handleFileChangeAndUpload(e, 'testing')
    console.log('imageURl:', imageUrl)
    setOldImageUrl(imageUrl)
    console.log('OldImageUrl:', oldImageUrl)
  }
  //For cancel button onClick
  const handleCancelCreate = () => {
    if (oldImageUrl !== imageUrl) {
      //delete image method from useDeleteImage Hook
      try {
        deleteImage(imageUrl)
      } catch (err) {
        console.error(err)
      }
    }

    navigate('/testing')
    console.log('update cancelled')
  }

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: '100vh',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault()
          handleSubmit()
        }}
      >
        <input type="text" {...title} />
        <input
          type="file"
          accept="image/*"
          onChange={handleImageUpload}
        ></input>
        {selectedImage && <img src={selectedImage} alt="Preview" width="200" />}
        <p>Image uploading: {uploadProgress} % done</p>
        {error && <h4>{error}</h4>}{' '}
        <button onClick={handleCancelCreate}>Cancel Create</button>{' '}
        <button
          type="submit"
          disabled={uploadProgress !== 0 && uploadProgress !== 100}
        >
          submit
        </button>
      </form>
    </div>
  )
}

export default Create
