import React from 'react'

import UpdateImage from '../UpdateImage'
import useRead from '../hooks/CRUD/useRead'
import Loader from '../Loader'
const EditAbout = () => {
  const { data, loading } = useRead('about')
  const { data: profile, profileLoading } = useRead('profile-photo')
  if (loading || profileLoading) return <Loader />
  return (
    <div>
      {data.map((item) => {
        return (
          <UpdateImage
            key={item.id}
            id={item.id}
            collectionName="about"
            isUpdateImage={true}
            isEditText={false}
          />
        )
      })}
      {profile.map((item) => {
        return (
          <UpdateImage
            key={item.id}
            id={item.id}
            collectionName="profile-photo"
            isUpdateImage={true}
            isEditText={false}
          />
        )
      })}
    </div>
  )
}

export default EditAbout
