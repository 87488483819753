import React, { useEffect, useState } from 'react'
import { Box } from '@mui/system'
import { IconButton, Typography } from '@mui/material'
import { Instagram } from '@mui/icons-material'
import { motion } from 'framer-motion'
const InstagramBlock = () => {
  const [instagramData, setInstagramData] = useState(null)

  useEffect(() => {
    const apiUrl =
      'https://graph.instagram.com/me/media?fields=media_url&access_token=IGQWRNMU9VdlJhaFk1TkcxSmx1d25Ya3MyUEpSYjc1RGhuNVAyc1JHVGpzU3ZAqZAkMzUTlXeGZAuOG1KMW41Rm1ERW9iaDItS1FNeGh6bFJVZA2REYWZAoTm12UFNzVFExdWVTTUJBMVNsZAmVlUQZDZD'

    const fetchData = async () => {
      try {
        const response = await fetch(apiUrl)

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`)
        }

        const data = await response.json()
        setInstagramData(data)
      } catch (error) {
        console.error('Error fetching data:', error.message)
      }
    }

    fetchData()
  }, [])

  //   console.log('data fetched:', instagramData.data)
  const images = instagramData ? instagramData.data : []
  return (
    <Box
      component={motion.div}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 2 }}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        opacity: 0,
        width: { xs: '100vw', sm: '90vw', md: '60vw', lg: '50vw' },
      }}
    >
      {/* Expanding line */}
      <Box
        textAlign="center"
        sx={{
          height: '1px',
          width: {
            xs: '400px',
            sm: '600px',
            md: '800px',
            lg: '1200px',
            xl: '1400px',
          },

          backgroundColor: '#000000',
          animation: ' expandAnimation 1s ease',
          mt: { xs: 3, sm: 5 },
          mb: { xs: 3, sm: 5 },
        }}
      ></Box>{' '}
      <Typography
        variant="h4"
        sx={{
          animation: 'blinking 2s infinite',
          '@keyframes blinking': {
            '0%': { opacity: 1 },
            '50%': { opacity: 0.4 },
            '100%': { opacity: 1 },
          },
          fontFamily: 'Lato',
        }}
      >
        Keep in Touch...
      </Typography>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {' '}
        <IconButton href="https://www.instagram.com/isakamera11/">
          <Instagram sx={{ color: '#E5C262', fontSize: '2rem' }} />
        </IconButton>
        <Typography
          variant="h5"
          sx={{ fontSize: '1.2rem', fontFamily: 'Afacad' }}
        >
          <a
            href="https://www.instagram.com/isakamera11/"
            style={{ color: '#E5C262' }}
          >
            @isakamer11
          </a>
        </Typography>
      </Box>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'left',
          px: { xs: 3 },
          height: '100%',
          flexWrap: 'wrap',
          mt: 5,
        }}
      >
        {images.map((item) => {
          return (
            <Box
              sx={{
                width: '80px',
                height: '80px',
                m: 1,
                transition: 'transform .8s ease',
                filter: 'grayscale(80%) blur(0.5px)',
                ':hover': {
                  transform: 'scale(1.1)',
                  filter: 'grayscale(0%) blur(0px) contrast(120%) ',
                },
              }}
            >
              {' '}
              <img
                src={item.media_url}
                alt={`fileId:${item.id}`}
                key={item.id}
                style={{
                  objectFit: 'cover',
                  width: '100%',
                  height: '100%',
                  display: 'block',
                }}
              />
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}

export default InstagramBlock
