import React, { useState, useEffect, useRef } from 'react'
import {
  Box,
  TextField,
  IconButton,
  Typography,
  Dialog,
  DialogContent,
  Tooltip,
  Divider,
} from '@mui/material'
import DeleteForeverSharpIcon from '@mui/icons-material/DeleteForeverSharp'
import DoneIcon from '@mui/icons-material/Done'
import useGetFileById from './hooks/useGetFileById'
import useUpdate from './hooks/CRUD/useUpdate'
import useDelete from './hooks/CRUD/useDelete'
import useAutoCloseDialog from './hooks/useAutoCloseDialog'
import useImageUpload from './hooks/useImageUpload'
import LinearBuffer from './LinearBuffer'
import { Undo } from '@mui/icons-material'
import useDeleteImage from './hooks/useDeleteImage'

const UpdateImage = ({
  id,
  collectionName,
  isUpdateImage = false,
  isEditText = true,
}) => {
  const { fileData } = useGetFileById(collectionName, id)
  const { updateDocument } = useUpdate()
  const {
    imageUrl,
    uploadProgress,
    selectedImage,
    handleFileChangeAndUpload,
    setSelectedImage,
  } = useImageUpload()
  const { deleteImage } = useDeleteImage()
  const { deleteDocument, error: deleteError } = useDelete()

  const { isDialogVisible, openDialog, closeDialog } = useAutoCloseDialog(
    false,
    1000,
  )

  const {
    isDialogVisible: isDeleteDialogVisible,
    openDialog: openDeleteDialog,
    closeDialog: closeDeleteDialog,
  } = useAutoCloseDialog(false, 1000)

  const {
    isDialogVisible: isCancelDialogVisible,
    openDialog: openCancelDialog,
    closeDialog: closeCancelDialog,
  } = useAutoCloseDialog(false, 1000)
  const inputRef = useRef(null)
  const [order, setOrder] = useState('')
  const [desc, setDesc] = useState('')
  const [imgUrl, setImgUrl] = useState('')
  const [oldImageUrl, setOldImageUrl] = useState(null)

  useEffect(() => {
    if (fileData) {
      setOrder(fileData.order)
      setDesc(fileData.desc)
      setImgUrl(fileData.imgUrl)
    }
  }, [fileData])

  const handleSubmit = (e) => {
    e.preventDefault()
    if (order !== undefined && desc !== undefined) {
      const newFile = {
        order: order,
        imgUrl: imageUrl || fileData.imgUrl,
        desc: desc,
      }
      console.log('New data:', newFile)
      updateDocument(collectionName, id, newFile)
      setSelectedImage(null)
      setImgUrl(fileData.imageUrl)
      openDialog()
    } else {
      console.error('Invalid data: Title or imageUrl is undefined')
    }
  }

  const handleImageUpload = async (e) => {
    e.preventDefault()

    await handleFileChangeAndUpload(e, collectionName)
    setOldImageUrl(imageUrl)

    console.log('imgUrl:', imageUrl)
  }

  const handleDelete = () => {
    deleteDocument(collectionName, id, fileData.imgUrl)
    openDeleteDialog()
  }

  const handleUploadClick = async () => {
    inputRef.current.click()
  }

  const handleCancelCreate = () => {
    if (oldImageUrl !== imageUrl) {
      try {
        deleteImage(imageUrl)
        setSelectedImage(null)

        openCancelDialog()
      } catch (err) {
        console.error(err)
      }
    }
  }

  return (
    <>
      <Box
        item
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          width: '100%',
          height: '100%',
          m: 1,
          p: 1,
        }}
      >
        {isUpdateImage ? (
          <>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                width: '100%',
                height: '100%',
              }}
            >
              <label htmlFor="upload-input">
                <input
                  type="file"
                  accept="image/*"
                  ref={inputRef}
                  onChange={handleImageUpload}
                  style={{ display: 'none' }}
                />{' '}
                <Box
                  sx={{
                    width: '200px',
                    height: '200px',
                    overflow: 'hidden',
                    position: 'relative',
                    cursor: 'pointer',
                    objectFit: 'cover',
                    border: '1px solid #c7c7c7',
                  }}
                >
                  {selectedImage ? (
                    <img
                      src={selectedImage}
                      alt="Preview"
                      marign="-200px 0px 0px -200px"
                      height="250px"
                      display="block"
                      onClick={handleUploadClick}
                    />
                  ) : (
                    <img
                      src={imgUrl}
                      alt={desc}
                      marign="-200px 0px 0px -200px"
                      height="250px"
                      display="block"
                      onClick={handleUploadClick}
                    />
                  )}
                </Box>
              </label>
              {uploadProgress > 0 && (
                <Box sx={{ mt: 2 }}>
                  <Typography>Upload Status:</Typography>
                  <LinearBuffer uploadProgress={uploadProgress} />
                </Box>
              )}
              {!isEditText && (
                <Typography variant="body1" sx={{ mt: 6 }}>
                  {desc}
                </Typography>
              )}
            </Box>
          </>
        ) : (
          <Box
            sx={{
              width: '150px',
              height: '150px',
              overflow: 'hidden',
              position: 'relative',
            }}
          >
            <img
              src={imgUrl}
              alt={desc}
              marign="-100px 0px 0px -100px"
              height="200px"
              display="block"
            />
          </Box>
        )}

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: { xs: 'column', md: 'row' },
          }}
        >
          {isEditText && (
            <>
              <TextField
                size="small"
                sx={{
                  mx: 2,
                  mt: { xs: 3, md: 4 },
                  width: { xs: '80%', md: '80px' },
                }}
                label="order"
                type="number"
                placeholder="Give a number for ordering"
                value={order}
                onChange={(e) =>
                  setOrder(e.target.value.replace(/[^0-9]/g, ''))
                }
              />

              <TextField
                label="description"
                type="text"
                placeholder="Add a description"
                value={desc}
                onChange={(e) => setDesc(e.target.value)}
                size="small"
                sx={{
                  mx: 2,
                  mt: { xs: 3, md: 4 },
                  width: { xs: '80%', md: '200px' },
                }}
              />
            </>
          )}

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {isEditText ? (
              <Tooltip title="Delete Photo">
                <IconButton
                  onClick={() => {
                    handleDelete()
                  }}
                >
                  <DeleteForeverSharpIcon sx={{ color: '#E5C262' }} />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Undo change">
                <IconButton onClick={handleCancelCreate}>
                  <Undo sx={{ color: '#E5C262' }} />
                </IconButton>
              </Tooltip>
            )}{' '}
            <Tooltip title="Update Change">
              <IconButton
                onClick={handleSubmit}
                disabled={uploadProgress !== 0 && uploadProgress !== 100}
              >
                <DoneIcon sx={{ color: '#E5C262' }} />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </Box>

      <Divider
        variant="middle"
        sx={{ borderColor: '#c7c7c7', mb: 6, mt: { xs: 7, sm: 2 } }}
      />
      <Dialog open={isDialogVisible} onClose={closeDialog}>
        <DialogContent>
          <Typography variant="h6">Updated successfully!</Typography>
        </DialogContent>
      </Dialog>
      <Dialog open={isDeleteDialogVisible} onClose={closeDeleteDialog}>
        <DialogContent>
          {deleteError ? (
            <Typography color="error" variant="h6">
              {deleteError}
            </Typography>
          ) : (
            <Typography variant="h6">Photo is Deleted</Typography>
          )}
        </DialogContent>
      </Dialog>
      <Dialog open={isCancelDialogVisible} onClose={closeCancelDialog}>
        <DialogContent>
          <Typography variant="h6">Upload Cancelled</Typography>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default UpdateImage
