import React from 'react'
import { Box, Typography } from '@mui/material'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import PhoneIcon from '@mui/icons-material/Phone'
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail'
const ContactBlock = ({ isDisplay = true }) => {
  return (
    isDisplay && (
      <Box
        sx={{
          display: 'flex',
          width: '100vw',
          height: '100%',
          flexDirection: 'column',
          alignItems: 'center',
          pt: 5,
          px: { xs: 5, sm: 6, md: 2 },
        }}
      >
        <Typography
          variant="h3"
          sx={{ fontSize: { xs: '1rem', sm: '1.5rem' }, color: '#ffffff' }}
        >
          Let me frame the flavour, one picture-perfect bite at a time....
        </Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: { xs: 'column', md: 'row' },
            mt: 3,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              mx: 5,
              mt: 2,
            }}
          >
            <LocationOnIcon sx={{ color: '#ffffff' }} />
            <Typography
              variant="body1"
              textAlign="center"
              color="#ffffff"
              gutterBottom
              sx={{
                fontSize: { xs: '0.8rem', md: '1rem' },
                fontFamily: 'Afacad',
              }}
            >
              South Waikto, New Zealand
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              mx: 5,
              mt: 2,
            }}
          >
            <PhoneIcon sx={{ color: '#ffffff' }} />
            <Typography
              variant="body1"
              textAlign="center"
              color="#ffffff"
              sx={{
                fontSize: { xs: '0.8rem', md: '1rem' },
                fontFamily: 'Afacad',
              }}
            >
              <a href="tel:+64220825163" style={{ color: '#ffffff' }}>
                +64 22 082 5163
              </a>
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              mx: 5,
              mt: 2,
            }}
          >
            <AlternateEmailIcon sx={{ color: '#ffffff' }} />
            <Typography
              variant="body1"
              textAlign="center"
              color="#ffffff"
              sx={{
                fontSize: { xs: '0.8rem', md: '1rem' },
                fontFamily: 'Afacad',
              }}
            >
              <a
                href="mailto:isakamera.contact@gmail.com"
                style={{ color: '#ffffff' }}
              >
                isakamera.contact@gmail.com
              </a>
            </Typography>
          </Box>
        </Box>
      </Box>
    )
  )
}

export default ContactBlock
