import React, { useEffect, useState } from 'react'
import useUpdate from '../hooks/CRUD/useUpdate'
import { useItemId } from '../../context/IdContext'
import useImageUpload from '../hooks/useImageUpload'
import useDeleteImage from '../hooks/useDeleteImage'
import useGetFileById from '../hooks/useGetFileById'
import { useNavigate } from 'react-router-dom'

const Update = () => {
  /*HOOKS*/

  //initialize useItemId hook
  const { selectedItemId, setId } = useItemId()
  //initialize useGetFileByID hook
  const { fileData } = useGetFileById('testing', selectedItemId)
  //initialize useUpdate hook
  const { updateDocument } = useUpdate()
  //initialize useDeleteImage hook
  const { deleteImage } = useDeleteImage()
  //initialize useNaviage hook
  const navigate = useNavigate()
  //initialize useImageUpload hook
  const { imageUrl, uploadProgress, selectedImage, handleFileChangeAndUpload } =
    useImageUpload()

  /*STATES*/

  //initialize loading state for getting file id from local storage
  const [loading, setLoading] = useState(true)
  //initialize input state for controlled input
  const [title, setTitle] = useState('')
  //initialize varible to store old url
  const [oldImageUrl, setOldImageUrl] = useState(null)

  /*SIDE EFFECTS*/

  //for prevent file id lost when refreshing
  useEffect(() => {
    const storedId = localStorage.getItem('selectedItemId')
    if (storedId) {
      setId(storedId)
      console.log('Id restored.')
    }
    setLoading(false)
  }, [setId])

  //Set input value
  useEffect(() => {
    if (fileData) {
      setTitle(fileData.title)
      setOldImageUrl(fileData.imgUrl)
    }
  }, [fileData])

  /*Functions*/

  //For upload button
  const handleImageUpload = async (e) => {
    e.preventDefault()
    //onChange and upload function from useImageupload Hook
    await handleFileChangeAndUpload(e, 'testing')
    console.log('new url:', imageUrl)

    console.log('old url:', oldImageUrl)
  }
  //For submit button
  const handleSubmit = (e) => {
    e.preventDefault()

    if (title !== undefined && imageUrl !== undefined) {
      const newFile = {
        title: title,
        imgUrl: imageUrl || fileData?.imgUrl || '',
      }
      console.log(newFile)
      //update function from useUpdate hook
      updateDocument('testing', selectedItemId, newFile)

      deleteImage(oldImageUrl)
      navigate('/testing')
    } else {
      console.error('Invalid data: Title or imageUrl is undefined')
    }
  }
  //For cancel button
  const handleCancelUpdate = () => {
    if (oldImageUrl !== imageUrl) {
      //deleteImage function from useDeleteImage hook
      try {
        deleteImage(imageUrl)
      } catch (err) {
        console.error(err)
      }
    }

    navigate('/testing')
    console.log('update cancelled')
  }

  if (loading) {
    return <div>Loading...</div>
  }
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: '100vh',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <input
        type="text"
        accept="image/*"
        value={title}
        onChange={(e) => {
          setTitle(e.target.value)
        }}
      />
      <input type="file" onChange={handleImageUpload}></input>
      <img
        src={selectedImage || fileData?.imgUrl || '/'}
        alt="Preview"
        width="200"
      />
      <p>Image uploading:{uploadProgress} % done</p>{' '}
      <button onClick={handleCancelUpdate}>cancel update</button>{' '}
      <button
        onClick={handleSubmit}
        disabled={uploadProgress !== 0 && uploadProgress !== 100}
      >
        submit
      </button>
    </div>
  )
}

export default Update
