import React from 'react'
import { Outlet } from 'react-router-dom'
import NavBar from '../components/NavBar'
import { motion } from 'framer-motion'
import Footer from '../components/Footer'
const Layout = () => {
  return (
    <motion.div
      initial={{ opacity: 0, transition: { duration: 1 } }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, transition: { duration: 1 } }}
    >
      <NavBar />

      <Outlet />
      <Footer />
    </motion.div>
  )
}

export default Layout
