import React from 'react'
import useRead from '../hooks/CRUD/useRead'
import { Box } from '@mui/material'
import Loader from '../Loader'
import NewUpload from '../NewUpload'
import UpdateImage from '../UpdateImage'
const EditPortrait = ({ onClick }) => {
  const { data, loading } = useRead('portraits')
  if (loading) return <Loader />
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100vw',
        }}
      >
        <NewUpload folder="portraits" />
        <Box
          sx={{
            mt: 5,
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            flexWrap: { xs: 'nowrap', sm: 'wrap' },
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {data.map((image) => (
            <UpdateImage
              key={image.id}
              id={image.id}
              collectionName="portraits"
            />
          ))}
        </Box>
      </Box>
    </>
  )
}

export default EditPortrait
