import React from 'react'
import { motion } from 'framer-motion'
import ViewPortraits from '../components/portraits/ViewPortraits'
const Portrait = () => {
  return (
    <motion.div
      initial={{ opacity: 0, transition: { duration: 1 } }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, transition: { duration: 1 } }}
    >
      <ViewPortraits />
    </motion.div>
  )
}

export default Portrait
