import { useState, useEffect } from 'react'
import { doc, getDoc } from 'firebase/firestore'
import { db } from '../../firebase-config'

const useGetFileById = (collectionName, fileId) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [fileData, setFileData] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)

        const fileDocRef = doc(db, collectionName, fileId)
        const fileDocSnapshot = await getDoc(fileDocRef)

        if (fileDocSnapshot.exists()) {
          const data = fileDocSnapshot.data()
          setFileData(data)
        } else {
          setError('File not found')
        }
      } catch (error) {
        console.error('Error fetching file by ID:', error)
        setError('Error fetching file')
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [collectionName, fileId])

  return { fileData, loading, error }
}

export default useGetFileById
